@font-face {
  font-family: 'Savoye LET Plain';
  src: local("Savoye LET Plain.1.0"),
  url("./assets/fonts/Savoye LET Plain.1.0.ttf");
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a1a6ab8a; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4a05c; 
}

.page-transition-enter {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.page-transiition-enter.page-transition-enter-active {
  opacity: 1;
}
.page-transition-leave.page-transition-leave-active {
  opacity: 0;
}

h2 {
  font-size: 1.5rem;
  color: #c4a05c;
  font-family: 'Savoye LET Plain', serif;
  font-size: 2rem;
  font-weight: normal;
  text-align: left;
  margin: 0;
}

h3 {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
}

p {
  font-size: .8rem;
  color: black;
  text-align: left;
}
strong {

  color: #c49f5c;
  font-weight: normal;
  font-family: 'Savoye LET Plain', serif;
  font-size: 1.5rem;
}
@media screen and (max-width: 1300px) {
 strong {
  font-size: 1.3rem;
 } 
}

em {
  font-size: 1.25rem;
  color: #c4a05c;
  font-family: 'Cinzel', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-style: normal;

}

ul {
  text-align: left;
  font-size: .8rem;
  color: black;
  list-style-type: circle;

}
li {
  margin-bottom: 0.5rem;
}
li::marker {
  color: #c4a05c;
}

button {
  font-size: 20pt;
}

  p, ul, li {font-size: 0.8rem;}

  

@media screen and (min-width: 1600px){
 h2 {
          font-size: 3rem;
      }
      p, ul, li  {
          font-size: 1.2rem;
      }
      strong {
        font-size: 1.9rem;
      }
}

